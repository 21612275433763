import React from "react";
import { FaWhatsapp } from "react-icons/fa"; // Importamos el icono de WhatsApp
import "./FloatingWhatsAppButton.css"; // Estilos para el botón

const FloatingWhatsAppButton = () => {
  const handleClick = () => {
    // Reemplaza el número de teléfono con el tuyo (formato internacional sin signos, ej. +52 para México).
    const phoneNumber = "525513000085";
    const message = "¡Hola! Me gustaría más información. de tus servicios y productos";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(whatsappURL, "_blank"); // Abre el enlace en una nueva pestaña
  };

  return (
    <div className="floating-whatsapp-button" onClick={handleClick}>
      <FaWhatsapp size={28} />
    </div>
  );
};

export default FloatingWhatsAppButton;
