import React from 'react';
import { useForm } from 'react-hook-form';
import './quote.css'


const QuoteForm = (props) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // Aquí puedes manejar el envío de datos, como realizar una solicitud POST a tu backend.
  };

  const supportedFormats = watch('file')?.[0]?.name?.match(/\.(stl|obj)$/i);

  return (
    <div style={{ maxWidth: "600px", margin: "auto", padding: "20px" }}>
       <div className="contact-section-title">
       <span className="thq-body-small"> <h2>Solicita tu cotización</h2></span>
       </div>
     
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Nombre */}
        <div className="contact-row">
          <div className="contact-content2">
          <label className="contact-text3" htmlFor="name">Nombre Completo:</label>
          <input
            type="text"
            id="name"
            className="thq-input"
            {...register('name', { required: "El nombre es obligatorio" })}
          />
          {errors.name && <span style={{ color: "red" }}>{errors.name.message}</span>}
          </div>
         </div>

        {/* Correo */}
        <div className="contact-row">
        <div className="contact-content2">
          <label className="contact-text4" htmlFor="email">Correo Electrónico:</label>
          <input
            type="email"
            id="email"
            className="thq-input"
            {...register('email', {
              required: "El correo es obligatorio",
              pattern: { value: /^\S+@\S+\.\S+$/, message: "Correo inválido" }
            })}
          />
          {errors.email && <span style={{ color: "red" }}>{errors.email.message}</span>}
         </div> 
        </div>

        {/* Descripción */}
        <div className="contact-row">
        <div className="contact-content2">
          <label className="contact-text4" htmlFor="description">Descripción del Proyecto:</label>
          <textarea
            id="description"
            className="thq-input"
            {...register('description', { required: "La descripción es obligatoria" })}
          />
          {errors.description && <span style={{ color: "red" }}>{errors.description.message}</span>}
          </div> 
        </div>

        {/* Material */}
        <div className="contact-row">
        <div className="contact-content2">
          <label className="contact-text4" htmlFor="material">Material:</label>
          <select className="thq-input" id="material" {...register('material', { required: "Selecciona un material" })}>
            <option value="">-- Selecciona un material --</option>
            <option value="PLA">PLA</option>
            <option value="ABS">ABS</option>
            <option value="PETG">PETG</option>
            <option value="Resina">Resina</option>
          </select>
          {errors.material && <span style={{ color: "red" }}>{errors.material.message}</span>}
          </div>
        </div>

        {/* Archivo */}
        <div className="contact-row">
        <div className="contact-content2">
          <label className="contact-text4" htmlFor="file">Sube tu archivo 3D (.stl, .obj):</label>
          <input
            type="file"
             className="thq-input"
            id="file"
            accept=".stl,.obj"
            {...register('file', {
              required: "Sube un archivo 3D",
              validate: {
                fileType: () => supportedFormats || "Solo se permiten archivos .stl o .obj"
              }
            })}
          />
          {errors.file && <span style={{ color: "red" }}>{errors.file.message}</span>}
          </div> 
        </div>
        <div className="contact-row">


          <button type="submit" className="thq-button-filled cta-button"   >
            <span class="thq-body-small">

              Enviar
            </span>
          </button>


        </div>
      </form>
    </div>
  );
};

export default QuoteForm;
