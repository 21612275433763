import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features22.css'

const Features22 = (props) => {
  return (
    <div className="features22-layout349 thq-section-padding">
      <div className="features22-max-width thq-section-max-width">
        <div className="features22-container1">
          <h2 className="thq-heading-2">
            {props.heading1 ?? (
              <Fragment>
                <span className="features22-text44">Materiales con los que trabajamos</span>
              </Fragment>
            )}
          </h2>
          <span className="features22-text11 thq-body-small">
            {props.content1 ?? (
              <Fragment>
                <span className="features22-text40">
                ¿En que materiales se puede imprimir en 3D?
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="features22-container2 thq-grid-auto-300">
          <div className="features22-card1 thq-card thq-flex-column">
            <img
              alt={props.feature1ImageAlt}
              src={props.feature1ImageSrc}
              className="features22-image1 thq-img-round thq-img-ratio-1-1"
            />
            <h2 className="thq-heading-2">
              {props.feature1Title ?? (
                <Fragment>
                  <span className="features22-text41">
                  Plástico PLA
                  </span>
                </Fragment>
              )}
            </h2>
            <span className="features22-text13 thq-body-small">
              {props.feature1Description ?? (
                <Fragment>
                  <span className="features22-text42">
                  Es un plástico biodegradable ideal para piezas con paredes finas y de alto detalle, ideal para piezas pequeñas y grandes, es más estable y más fácil de imprimir.
                  <br /><br /><span className="thq-heading-3">Aplicaciones:</span>
                    <ul className='features22-item-element'>
                    <li>Modelos conceptuales</li>
                    <li>Prototipos estéticos</li>
                  </ul>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features22-card2 thq-card thq-flex-column">
            <img
              alt={props.feature2ImageAlt}
              src={props.feature2ImageSrc}
              className="features22-image2 thq-img-round thq-img-ratio-1-1"
            />
            <h2 className="thq-heading-2">
              {props.feature2Title ?? (
                <Fragment>
                  <span className="features22-text43">
                  Plástico ABS 
                  </span>
                </Fragment>
              )}
            </h2>
            <span className="features22-text15 thq-body-small">
              {props.feature2Description ?? (
                <Fragment>
                  <span className="features22-text29">
                  Es un plástico muy común y resistente, es con el que están hechos los LEGO, ideal para la impresión de piezas mecánicas. Se puede lijar con una lija de madera y pulir aplicando ligeramente un trapo con acetona. Se puede también taladrar o pintar.
                  <br /><br /><span className="thq-heading-3">Aplicaciones:</span>
                    <ul className='features22-item-element'>
                    <li>Prototipos funcionales</li>
                  </ul>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          {/* <div className="features22-card3 thq-card thq-flex-column">
            <img
              alt={props.feature3ImageAlt}
              src={props.feature3ImageSrc}
              className="features22-image3 thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="thq-heading-2">
              {props.feature3Title ?? (
                <Fragment>
                  <span className="features22-text30">
                  PLA PHA
                  </span>
                </Fragment>
              )}
            </h1>
            <span className="features22-text17 thq-body-small">
              {props.feature3Description ?? (
                <Fragment>
                  <span className="features22-text39">
                  Bioplástico que combina el alto detalle del PLA y la dureza y resistencia del ABS
                  </span>
                </Fragment>
              )}
            </span>
          </div> */}
          <div className="features22-card4 thq-card thq-flex-column">
            <img
              alt={props.feature4ImageAlt}
              src={props.feature4ImageSrc}
              className="features22-image4 thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="thq-heading-2">
              {props.feature4Title ?? (
                <Fragment>
                  <span className="features22-text32">PET G</span>
                </Fragment>
              )}
            </h1>
            <span className="features22-text19 thq-body-small">
              {props.feature4Description ?? (
                <Fragment>
                  <span className="features22-text38">
                  Es un material ligero y resistente a los golpes, se encuentra en las botellas y envases de plástico que consumimos, es más resistente que el PLA y el ABS entre sus virtudes también cuenta con grado alimenticio.
                  <br /><br /><span className="thq-heading-3">Aplicaciones:</span>
                    <ul className='features22-item-element'>
                    <li>Aplicaciones impermeables</li>
                    <li>Componentes con mecanica</li>
                    <li>Puede ser apto para alimentacion</li>
                  </ul>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          {/* <div className="features22-card5 thq-card thq-flex-column">
            <img
              alt={props.feature5ImageAlt}
              src={props.feature5ImageSrc}
              className="features22-image5 thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="thq-heading-2">
              {props.feature5Title ?? (
                <Fragment>
                  <span className="features22-text45">PLA sin rayas</span>
                </Fragment>
              )}
            </h1>
            <span className="features22-text21 thq-body-small">
              {props.feature5Description ?? (
                <Fragment>
                  <span className="features22-text28">
                  Es un plástico PLA diseñado para ser procesado en alcohol y el resultado es un acabado liso y brillante espectacular.

                  </span>
                </Fragment>
              )}
            </span>
          </div>
           */}
          <div className="features22-card7 thq-card thq-flex-column">
            <img
              alt={props.feature7ImageAlt}
              src={props.feature7ImageSrc}
              className="features22-image7 thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="thq-heading-2">
              {props.feature7Title ?? (
                <Fragment>
                  <span className="features22-text34">Nylon</span>
                </Fragment>
              )}
            </h1>
            <span className="features22-text25 thq-body-small">
              {props.feature7Description ?? (
                <Fragment>
                  <span className="features22-text36">
                  Material que ofrece buenas propiedades de amortiguación y resistencia al impacto ademas de ser muy durable tiene alta resistencia al desgaste y a productos químicos.
                  <br /><br /><span className="thq-heading-3">Aplicaciones:</span>
                    <ul className='features22-item-element'>
                    <li>Prototipos funcionales</li>
                    <li>Piezas resistentes al desgaste</li>
                  </ul>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features22-card8 thq-card thq-flex-column">
            <img
              alt={props.feature8ImageAlt}
              src={props.feature8ImageSrc}
              className="features22-image8 thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="thq-heading-2">
              {props.feature8Title ?? (
                <Fragment>
                  <span className="features22-text35">Flexible</span>
                </Fragment>
              )}
            </h1>
            <span className="features22-text27 thq-body-small">
              {props.feature8Description ?? (
                <Fragment>
                  <span className="features22-text33">
                  Es similar al caucho o goma, es elástico por lo tanto vuelve a su forma original al deformarlo, se pueden imprimir por ejemplo fundas de smartphones, fundas de tablets, zapatillas, plantillas, ruedas de RC, prótesis, y en general, cualquier objeto que se te ocurra y pueda hacer uso de sus propiedades.
                  <br /><br /><span className="thq-heading-3">Aplicaciones:</span>
                    <ul className='features22-item-element'>
                    <li>Prototipos flexibles</li>
                  </ul>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="features22-card9 thq-card thq-flex-column">
            <img
              alt={props.feature9ImageAlt}
              src={props.feature9ImageSrc}
              className="features22-image9 thq-img-round thq-img-ratio-1-1"
            />
            <h1 className="thq-heading-2">
              {props.feature9Title ?? (
                <Fragment>
                  <span className="features22-text35">Madera</span>
                </Fragment>
              )}
            </h1>
            <span className="features22-text27 thq-body-small">
              {props.feature9Description ?? (
                <Fragment>
                  <span className="features22-text33">
                  Es un filamento hecho a partir de la combinación de plástico PLA y fibras de madera, lo cual crea un acabado similar a la madera. Las piezas pueden ser lijadas o pintadas y se utiliza principalmente para impresión 3D de elementos decorativos.
                  <br /><br /><span className="thq-heading-3">Aplicaciones:</span>
                    <ul className='features22-item-element'>
                      <li >Prototipos esteticos</li>
                    </ul>
                    </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Features22.defaultProps = {
  feature5Description: undefined,
  feature5ImageAlt: 'Testimonials Image Alt Text',
  feature2Description: undefined,
  feature4ImageAlt: 'Pricing Plans Image Alt Text',
  feature3Title: undefined,
  feature1ImageAlt: 'High-Quality 3D Printing Image Alt Text',
  feature6Description: undefined,
  feature8ImageAlt: 'image',
  feature9ImageAlt: 'image',
  feature4Title: undefined,
  feature8Description: undefined,
  feature2ImageAlt: 'Prototyping Expertise Image Alt Text',
  feature7Title: undefined,
  feature5ImageSrc:
    'https://images.unsplash.com/photo-1631700611307-37dbcb89ef7e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjA3OTgyNHw&ixlib=rb-4.0.3&q=80&w=1080',
  feature8Title: undefined,
  feature9Title: undefined,
  feature7Description: undefined,
  feature6Title: undefined,
  feature7ImageSrc:
    'https://images.unsplash.com/photo-1609921212029-bb5a28e60960?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjA3OTgyNHw&ixlib=rb-4.0.3&q=80&w=1080',
  feature4Description: undefined,
  feature1ImageSrc:
    'https://images.unsplash.com/photo-1705595461556-c93d62a86c25?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjA3OTgyNHw&ixlib=rb-4.0.3&q=80&w=1080',
  feature3Description: undefined,
  feature8ImageSrc:
    'https://images.unsplash.com/photo-1693744460058-d73458442d3d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjA3OTgyNHw&ixlib=rb-4.0.3&q=80&w=1080',
    feature9ImageSrc:
    'https://images.unsplash.com/photo-1693744460058-d73458442d3d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjA3OTgyNHw&ixlib=rb-4.0.3&q=80&w=1080',
 
    feature4ImageSrc:
    'https://images.unsplash.com/photo-1533000247305-2061d09448e9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjA3OTgyNXw&ixlib=rb-4.0.3&q=80&w=1080',
  feature6ImageSrc:
    'https://images.unsplash.com/photo-1457433575995-8407028a9970?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjA3OTgyNXw&ixlib=rb-4.0.3&q=80&w=1080',
  content1: undefined,
  feature2ImageSrc:
    'https://images.unsplash.com/photo-1635800151660-9dcf3b034365?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjA3OTgyNXw&ixlib=rb-4.0.3&q=80&w=1080',
  feature3ImageAlt: 'Recent Projects Showcase Image Alt Text',
  feature6ImageAlt: 'image',
  feature7ImageAlt: 'image',
  feature3ImageSrc:
    'https://images.unsplash.com/photo-1525909002-1b05e0c869d8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMjA3OTgyNXw&ixlib=rb-4.0.3&q=80&w=1080',
  feature1Title: undefined,
  feature1Description: undefined,
  feature2Title: undefined,
  heading1: undefined,
  feature5Title: undefined,
}

Features22.propTypes = {
  feature5Description: PropTypes.element,
  feature5ImageAlt: PropTypes.string,
  feature2Description: PropTypes.element,
  feature4ImageAlt: PropTypes.string,
  feature3Title: PropTypes.element,
  feature1ImageAlt: PropTypes.string,
  feature6Description: PropTypes.element,
  feature8ImageAlt: PropTypes.string,
  feature9ImageAlt: PropTypes.string,
  feature4Title: PropTypes.element,
  feature8Description: PropTypes.element,
  feature9Description: PropTypes.element,
  feature2ImageAlt: PropTypes.string,
  feature7Title: PropTypes.element,
  feature5ImageSrc: PropTypes.string,
  feature8Title: PropTypes.element,
  feature9Title: PropTypes.element,
  feature7Description: PropTypes.element,
  feature6Title: PropTypes.element,
  feature7ImageSrc: PropTypes.string,
  feature4Description: PropTypes.element,
  feature1ImageSrc: PropTypes.string,
  feature3Description: PropTypes.element,
  feature8ImageSrc: PropTypes.string,
  feature9ImageSrc: PropTypes.string,
  feature4ImageSrc: PropTypes.string,
  feature6ImageSrc: PropTypes.string,
  content1: PropTypes.element,
  feature2ImageSrc: PropTypes.string,
  feature3ImageAlt: PropTypes.string,
  feature6ImageAlt: PropTypes.string,
  feature7ImageAlt: PropTypes.string,
  feature3ImageSrc: PropTypes.string,
  feature1Title: PropTypes.element,
  feature1Description: PropTypes.element,
  feature2Title: PropTypes.element,
  heading1: PropTypes.element,
  feature5Title: PropTypes.element,
}

export default Features22
