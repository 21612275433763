import React from 'react'

import PropTypes from 'prop-types'
import {  Link, Outlet } from 'react-router-dom'

import './navbar.css'
import QuoteForm from '../views/quote'

const Navbar = (props) => {
  return (
    <>
    <header className="navbar-container">
      <header data-thq="thq-navbar" className="navbar-navbar-interactive">
      <Link to="/"> <img
          alt={props.logoAlt}
          src={props.logoSrc}
          className="navbar-image1"
        /></Link>
        <div data-thq="thq-navbar-nav" className="navbar-desktop-menu">
          <nav className="navbar-links1">
            <span className="thq-link thq-body-small"><Link to="/">{props.link1}</Link></span>
            <span className="thq-link thq-body-small"><Link to="/services">{props.link2}</Link></span> 
            <span className="thq-link thq-body-small"><Link to="/portfolio">{props.link3}</Link></span>
            {/* <span className="thq-link thq-body-small"><Link to="/about">{props.link4}</Link></span> */}
            <span className="thq-link thq-body-small"><Link to="/contact">{props.link5}</Link></span>
          </nav>
          <div className="navbar-buttons1">
            <button className="navbar-action11 thq-button-filled thq-button-animated">
              <span className="thq-body-small"><Link to="/quote">Realizar una cotizacion</Link></span>
            </button>
            <button className="navbar-action21 thq-button-animated thq-button-outline">
              <span className="thq-body-small">Contactanos</span>
            </button>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="navbar-burger-menu">
          <svg viewBox="0 0 1024 1024" className="navbar-icon1">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="navbar-mobile-menu">
          <div className="navbar-nav">
            <div className="navbar-top">
              <img
                alt={props.logoAlt}
                src={props.logoSrc}
                className="navbar-logo"
              />
              <div data-thq="thq-close-menu" className="navbar-close-menu">
                <svg viewBox="0 0 1024 1024" className="navbar-icon3">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="navbar-links2">
              <span className="thq-link thq-body-small">{props.link1}</span>
              <span className="thq-link thq-body-small">{props.link2}</span>
              <span className="thq-link thq-body-small">{props.link3}</span>
              <span className="thq-link thq-body-small">{props.link4}</span>
              <span className="thq-link thq-body-small">{props.link5}</span>
            </nav>
          </div>
          <div className="navbar-buttons2">
            <button className="thq-button-filled">Inicia sesion</button>
            <button className="thq-button-outline">Registro</button>
          </div>
        </div>
      </header>
    </header>
    <main><Outlet /></main>
    </>
  )
}

Navbar.defaultProps = {
  link4: 'Acerca de nosotros',
  action1: '/',
  logoSrc:
    '/Images/Arrecife.png',
  link5: 'Contacto',
  logoAlt: 'Arrecife 3D Estudio',
  link3: 'Portafolio',
  link2: 'Servicios',
  action2: '#services',
  link1: 'Inicio',
}

Navbar.propTypes = {
  link4: PropTypes.string,
  action1: PropTypes.string,
  logoSrc: PropTypes.string,
  link5: PropTypes.string,
  logoAlt: PropTypes.string,
  link3: PropTypes.string,
  link2: PropTypes.string,
  action2: PropTypes.string,
  link1: PropTypes.string,
}

export default Navbar
