import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hero.css'

const Hero = (props) => {
  return (
    <div className="hero-header78">
	<div className="hero-column thq-section-padding thq-section-max-width">
		<div className="hero-content1">
			<h1 className="hero-text1 thq-heading-1">{props.heading1}</h1>
      <h2 className="hero-text1 thq-heading-2">{props.heading1b}</h2>
			<p className="hero-text2 thq-body-large">{props.content1}</p>
		</div>
		<div className="hero-actions">
			<button className="thq-button-filled hero-button1">
				<span className="thq-body-small">{props.action1}</span>
			</button>
			<button className="thq-button-outline hero-button2">
				<span className="thq-body-small">{props.action2}</span>
			</button>
		</div>
	</div>
	<div className="hero-content2">
		<div className="hero-row-container1 thq-animated-group-container-horizontal thq-mask-image-horizontal">
			<div className="thq-animated-group-horizontal">
				<img alt={props.image1Alt} src={props.image1Src} className="hero-placeholder-image10 thq-img-ratio-1-1 thq-img-scale"/>
				<img alt={props.image2Alt} src={props.image2Src} className="hero-placeholder-image11 thq-img-ratio-1-1 thq-img-scale"/>
				<img alt={props.image3Alt} src={props.image3Src} className="hero-placeholder-image12 thq-img-ratio-1-1 thq-img-scale"/>
				<img alt={props.image4Alt} src={props.image4Src} className="hero-placeholder-image13 thq-img-ratio-1-1 thq-img-scale"/>
				<img alt={props.image5Alt} src={props.image5Src} className="hero-placeholder-image14 thq-img-ratio-1-1 thq-img-scale"/>
				<img alt={props.image6Alt} src={props.image6Src} className="hero-placeholder-image15 thq-img-ratio-1-1 thq-img-scale"/>
			</div>
			<div className="thq-animated-group-horizontal">
				<img alt={props.image1Alt} src={props.image1Src} className="hero-placeholder-image16 thq-img-ratio-1-1 thq-img-scale"/>
				<img alt={props.image2Alt} src={props.image2Src} className="hero-placeholder-image17 thq-img-ratio-1-1 thq-img-scale"/>
				<img alt={props.image3Alt} src={props.image3Src} className="hero-placeholder-image18 thq-img-ratio-1-1 thq-img-scale"/>
				<img alt={props.image4Alt} src={props.image4Src} className="hero-placeholder-image19 thq-img-ratio-1-1 thq-img-scale"/>
				<img alt={props.image5Alt} src={props.image5Src} className="hero-placeholder-image20 thq-img-ratio-1-1 thq-img-scale"/>
				<img alt="Hero Image" src="https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1500" className="hero-placeholder-image21 thq-img-ratio-1-1 thq-img-scale"/>
			</div>
		</div>
		
	</div>
	<div>
		<div className="hero-container2">
			<Script html={`<style> @keyframes scroll-x { from { transform: translateX(0); } to { transform: translateX(calc(-100% - 16px)); } } @keyframes scroll-y { from { transform: translateY(0); } to { transform: translateY(calc(-100% - 16px)); } }
		</style>
`}
          ></Script>
</div>
</div>
</div>
  )
}

Hero.defaultProps = {
  image1Src:'/Images/impresion01.jpg',
  image2Src: '/Images/impresion02.png',
  image3Src: '/Images/impresion03.jpg',
  image4Src: '/Images/impresion04.jpg',
  image5Src: '/Images/impresion05.png',
  image6Src: '/Images/impresion06.png',
  image7Src: '/Images/impresion07.png', 
  image8Src: 'https://images.unsplash.com/photo-1585163218247-7a44236dd0f5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMTcyOTY5MHw&ixlib=rb-4.0.3&q=80&w=1080',
  image9Src: 'https://images.unsplash.com/photo-1573164713988-8665fc963095?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMTcyOTY5MHw&ixlib=rb-4.0.3&q=80&w=1080',
  image11Src: 'https://images.unsplash.com/photo-1599950753894-d84cbcce4e95?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMTcyOTY4Nnw&ixlib=rb-4.0.3&q=80&w=1080',
	image8Alt: 'Innovative Technology',
	image2Alt: '3D Printing Technology',
    action1: 'Iniciemos',
	action2: 'Conoce mas',
  image3Alt: 'Design Prototypes',
  image4Alt: 'Recent Jobs',
  image9Alt: 'Experienced Team',
  image11Alt: 'Customized Solutions',
  heading1: 'Descubre Arrecife 3D Studio:',
  heading1b: 'Donde tus ideas toman forma',
 image12Alt: 'Fast Turnaround',
  image10Src:
    'https://images.unsplash.com/photo-1457433575995-8407028a9970?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMTcyOTY5MXw&ixlib=rb-4.0.3&q=80&w=1080',
  image10Alt: 'Customer Satisfaction',
  image1Alt: '3D Printing and Design Services',
  image12Src:
    'https://images.unsplash.com/photo-1510070009289-b5bc34383727?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTczMTcyOTY4OXw&ixlib=rb-4.0.3&q=80&w=1080',
  image5Alt: 'Professional 3D Printing',
  image6Alt: 'Creative Design Solutions',

  image7Alt: 'Quality Prototyping',
 
  content1:
    'En Arrecife 3D Studio, transformamos tus ideas en realidad con algunas de las tecnologías de impresión 3D de última generación. Ofrecemos soluciones personalizadas para proyectos creativos, prototipos funcionales y objetos únicos, con un enfoque en la calidad, precisión y satisfacción del cliente. Ya sea que busques materializar una visión o explorar las infinitas posibilidades del diseño 3D, estamos aquí para ayudarte a dar vida a tus proyectos. ¡Imagina, diseña y crea con nosotros!',
  
}

Hero.propTypes = {
  image6Src: PropTypes.string,
  image8Alt: PropTypes.string,
  action1: PropTypes.string,
  image9Src: PropTypes.string,
  image2Alt: PropTypes.string,
  action2: PropTypes.string,
  image3Alt: PropTypes.string,
  image4Alt: PropTypes.string,
  image4Src: PropTypes.string,
  image9Alt: PropTypes.string,
  image11Alt: PropTypes.string,
  image11Src: PropTypes.string,
  heading1: PropTypes.string,
  image8Src: PropTypes.string,
  image12Alt: PropTypes.string,
  image10Src: PropTypes.string,
  image10Alt: PropTypes.string,
  image1Alt: PropTypes.string,
  image12Src: PropTypes.string,
  image5Alt: PropTypes.string,
  image7Src: PropTypes.string,
  image6Alt: PropTypes.string,
  image1Src: PropTypes.string,
  image7Alt: PropTypes.string,
  image5Src: PropTypes.string,
  image3Src: PropTypes.string,
  content1: PropTypes.string,
  image2Src: PropTypes.string,
}

export default Hero
