import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Hero from '../components/hero'
import Features1 from '../components/features1'
import CTA from '../components/cta'
import Features2 from '../components/features2'
import Features22 from '../components/features22'
import Pricing from '../components/pricing'
import Steps from '../components/steps'
import Testimonial from '../components/testimonial'
import Contact from '../components/contact'
import Footer from '../components/footer'
import FloatingWhatsAppButton from "../components/FloatingWhatsAppButton";


import './home.css'
import Gallery7 from '../components/gallery7'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Arrecife 3D Estudio</title>
      </Helmet>
      {/* <Navbar></Navbar> */}
      <Hero></Hero>
      <Features1></Features1>
      <CTA></CTA>
      <Features22></Features22>
      <Steps></Steps>
      <Gallery7></Gallery7>
      <Contact></Contact>
      <Footer></Footer>
      <FloatingWhatsAppButton />
    </div>
  )
}

export default Home
