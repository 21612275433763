import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  createBrowserRouter, RouterProvider, Routes,Navigate
} from 'react-router-dom'

import { Switch } from 'react-switch'


import './style.css'
import Home from './views/home'
import NotFound1 from './views/not-found'
import Navbar
 from './components/navbar'
import QuoteForm from './views/quote'
import Gallery from './components/gallery7'
import Contact from './components/contact'
import Features22 from './components/features22'
import Features1  from './components/features1'
import FloatingWhatsAppButton from "./components/FloatingWhatsAppButton";

const App = () => {
  return (

    <Router>
         <Navbar />
         <FloatingWhatsAppButton />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<QuoteForm />} path="/quote" />
        <Route element={<Gallery />} path="/portfolio" />
        <Route element={<Contact />} path="/contact" />
        <Route element={<Features1/>} path="/services" />
        <Route element={<NotFound1 />} exact path="/not-found" />
        <Route path="*" element={<NotFound1 />} />
      </Routes>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
