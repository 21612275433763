 import React from 'react'
 import {  Link, Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'

import './cta.css'


const CTA = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="thq-section-max-width">
        <div className="cta-accent2-bg">
          <div className="cta-accent1-bg">
            <div className="cta-container2">
              <div className="cta-content">
                <span className="thq-heading-2">{props.heading1}</span>
                <p className="thq-body-large">{props.content1}</p>
              </div>
              <div className="cta-actions">
                <button type="button" className="thq-button-filled cta-button"   >
                <Link to="/quote">{props.action1}</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CTA.defaultProps = {
  heading1: 'Estas listo para materializar ese proyecto? Entonces vamos!',
  content1:
    'Transforme sus ideas en realidad con nuestros servicios de diseño y impresión 3D, empecemos por realizar un presupuesto.',
  action1: 'Obten un presupuesto',
}

CTA.propTypes = {
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action1: PropTypes.string,
}

export default CTA
